<template>
<ActsSummary v-if="sum" :sum="sum" ref="summary"/>
<div
      class="table__container table__container_acts"
      :style="initialTopHeight"
      v-if="paymentList.length"
      ref="topSection">
      <Payments
        :items="paymentList"
        :filterByAct="filterByAct"
        @highlightAct="highlightAct"
        @resetActFilter="reset"
        @showButton="switchButton"
        @refresh="refreshData"
      />
    </div>
      <div class="resizer resizer_hor" ref="resizer">
    <transition name="fade">
      <ActsBindingButton
        v-if="showBindingButton"
        :sum="calculatedSum"
        :disabled="!isPossibleToBind"
        @bind="bindPayment"
      />
    </transition>
    <transition name="fade">
      <ActUnbindingButton
        v-if="showUnbindingButton"
        @unbind="unbindPayment"
      />
    </transition>
  </div>
<div
  class="table__container table__container_acts"
  :style="initialBottomHeight"
  ref="bottomSection"
  >
  <div class="doc-field__notice" v-if="!items.length && notice">
    Нет актов по этому договору
    <mcw-button
      @click="showActCreateConfirm = true">
      создать акт
    </mcw-button>
  </div>
  <div
    class="table"
    ref="table">
    <mdc-data-table
      sticky
      v-if="items.length">
      <div class="table__header">
      </div>
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell">
              <mcw-checkbox
                ref="rowCheckbox"
                class="mdc-data-table__header-row-checkbox"
                v-show="false"
              ></mcw-checkbox>
              <mcw-button
                v-if='userRank >= 100'
                @click="showActCreateConfirm = true">
                создать
              </mcw-button>
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('id')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('act_status_text')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('date_add', 'date')">
              {{transcriptColumnTitle('date_add')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('payment_type')}}
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('sum_act')}}
            </th>
            <th class="mdc-data-table__header-cell cell-with-dropdown">
              <IconFilter
                :options="paydFilter"
                :selected="selectedFilter"
                @select="onSelect"
              />
            </th>
            <th class="mdc-data-table__header-cell">
              {{transcriptColumnTitle('who_add')}}
            </th>
          </tr>
        </thead>
        <tbody
          class="mdc-data-table__content">
          <template
            v-for="item in filteredActs"
            :key="item.id">
            <tr
              :ref="'row-' + item.id"
              :style="idCellStyles[item.act_status]"
              :class="['mdc-data-table__row', 'row_expandable', { dragover: dragoverId === item.id }]"
              draggable="true"
              :data-id="item.id"
              @dragstart="startDrag($event)"
              @dragover.prevent="onDragOver($event, item.id)"
              @dragend="onDragOver($event, null)"
              @drop.stop.prevent="onDrop($event, item.id)"
              @click="expand(item.id)">
              <td class="mdc-data-table__cell mdc-data-table__cell--checkbox table__cell">
                <mcw-checkbox
                  ref="rowCheckbox"
                  class="mdc-data-table__row-checkbox"
                  :value="item.id"
                  v-model="checkedActs"
                  @change="setAct(item.id)"
                ></mcw-checkbox>
              </td>
              <td
                class="mdc-data-table__cell table__cell_clickable"
                @click="openActDialog(item.id)">
                {{item.id}}
              </td>
              <td class="mdc-data-table__cell">
                {{item.act_status_text}}
              </td>
              <td class="mdc-data-table__cell">
                {{item.date_add}}
              </td>
              <td class="mdc-data-table__cell">
                {{item.payment_type}}
              </td>
              <td class="mdc-data-table__cell mdc-data-table__cell--numeric">
                {{showRank(item.sum_act)}}
              </td>
              <td :class="['mdc-data-table__cell mdc-data-table__cell--numeric', {table__cell_clickable: item.list_pay}]"
                  @click="onSumActSelect(item)">
                {{showRank(item.sum_pay)}}
              </td>
              <td class="mdc-data-table__cell">
                {{item.who_add}}
              </td>
            </tr>
            <tr v-show="expanded === item.id">
              <td colspan="4">
                <table class="act__expanded-table">
                  <tbody>
                    <tr>
                      <td>{{transcriptColumnTitle('date_act')}}</td>
                      <td>{{item.date_act}}</td>
                    </tr>
                    <tr>
                      <td>{{transcriptColumnTitle('date_from')}}</td>
                      <td>{{item.date_from}}</td>
                    </tr>
                    <tr>
                      <td>{{transcriptColumnTitle('date_till')}}</td>
                      <td>{{item.date_till}}</td>
                    </tr>
                    <tr>
                      <td>{{transcriptColumnTitle('comment')}}</td>
                      <td>{{item.comment}}</td>
                    </tr>
                  </tbody>
                </table>
                </td>
                <td colspan="4">
                <mcw-button
                  v-if='userRank >= 100'
                  @click="approveAct(item.id)">
                  утвердить акт
                </mcw-button>
                <mcw-button
                  v-if='userRank >= 100'
                  @click="cancelAct(item.id)">
                  отменить акт
                </mcw-button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </mdc-data-table>
    </div>
  </div>
    <mcw-dialog
    v-model="isActDialog"
    escape-key-action="close">
    <LoadPatients title="Перейти к пациентам по акту?" @loadPatients="loadPatients"/>
  </mcw-dialog>
  <mcw-dialog
    v-model="showActCreateConfirm"
    escape-key-action="close">
    <mcw-dialog-title>Подтвердите создание акта</mcw-dialog-title>
    <mcw-dialog-content>
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button action="dismiss">Отмена</mcw-dialog-button>
      <mcw-dialog-button action="accept" @click="createAct">Создать</mcw-dialog-button>
    </mcw-dialog-footer>
  </mcw-dialog>
  <mcw-dialog
    v-model="showMergeConfirm"
    escape-key-action="close">
    <mcw-dialog-title>Подтвердите слияние актов</mcw-dialog-title>
    <mcw-dialog-content>
      Присоединить {{ mergeParams?.id_act_victim }} к {{ mergeParams?.id_act }}?
    </mcw-dialog-content>
    <mcw-dialog-footer>
      <mcw-dialog-button action="dismiss">Отмена</mcw-dialog-button>
      <mcw-dialog-button action="accept" @click="mergeActs">Ок</mcw-dialog-button>
    </mcw-dialog-footer>
  </mcw-dialog>
  <Spinner v-if="isLoading" />
</template>

<script>
import { RESIZER_SIZE, ACTS_STATUSES_MAP } from '@/constants';
import { utils } from '@/helpers/utils';
import { resizer } from '@/helpers/resizerMixin';
import LoadPatients from '../LoadPatientsDialog.vue';
import CommonTable from '../DataTables/CommonTable.vue';
import Payments from './Payments.vue';
import ActsSummary from './ActsSummary.vue';
import Spinner from '../Spinner.vue';
import IconFilter from '../IconFilter.vue';
import ActsBindingButton from './Buttons/ActsBindingButton.vue';
import ActUnbindingButton from './Buttons/ActUnbindingButton.vue';

export default {
  name: 'Acts',
  extends: CommonTable,
  mixins: [
    utils,
    resizer,
  ],
  props: {
    items: Array,
    contractId: Number,
    notice: Boolean,
    tableHeight: Number,
    loading: Boolean,
  },
  emits: [
    'refresh',
  ],
  components: {
    LoadPatients,
    Payments,
    ActsSummary,
    IconFilter,
    ActsBindingButton,
    ActUnbindingButton,
    Spinner,
  },
  data() {
    return {
      activeActId: null,
      actsPatients: [],
      isActDialog: false,
      isLoading: false,
      selectedAct: null,
      selectedFilter: null,
      showUnbindingButton: false,
      idActPay: null,
      filterByAct: [],
      showBindingButton: false,
      expanded: null,
      dragoverId: null,
      showActCreateConfirm: false,
      mergeParams: null,
      showMergeConfirm: false,
    };
  },
  computed: {
    checkedActs: {
      get() {
        return this.$store.getters['contractsData/getCheckedActs'];
      },
      set(val) {
        this.$store.commit('contractsData/SET_CHECKED_ACTS', val);
      },
    },
    userRank() {
      return this.$store.getters['user/getUserRank'];
    },
    checkedPayments() {
      return this.$store.getters['contractsData/getCheckedPayments'];
    },
    paydFilter() {
      return [
        {
          name: 'Оплаченные',
          value: 1,
          icon: 'check_circle',
        },
        {
          name: 'Неоплаченные',
          value: 2,
          icon: 'unpublished',
        },
        {
          name: 'Все',
          value: 3,
          icon: 'rule',
        },
      ];
    },
    initialTopHeight() {
      return 'height: 34%';
    },
    initialBottomHeight() {
      return `height: calc(66% - ${RESIZER_SIZE + 50}px`;
    },
    paymentList() {
      return this.$store.getters['contractsData/getPaymentList'];
    },
    isPossibleToBind() {
      return this.checkedActs.length && this.checkedPayments.length;
    },
    sum() {
      return this.$store.state.contractsData.actsSummary;
    },
    filteredActs() {
      if (this.selectedFilter.value === 1) {
        return this.items.filter((item) => item.sum_pay >= item.sum_act);
      }
      if (this.selectedFilter.value === 2) {
        return this.items.filter((item) => item.sum_pay < item.sum_act);
      }
      return this.items;
    },
    calculatedSum() {
      if (!this.checkedActs.length && !this.checkedPayments.length) {
        return 0;
      }
      const act = this.items.find((item) => item.id === this.checkedActs[0]);
      const actSum = act?.sum_act - act?.sum_pay;
      const payment = this.paymentList.find((item) => item.id_pay === this.checkedPayments[0]);
      const paySum = payment?.sum_pay - payment?.sum_take_total;
      if (!this.checkedPayments.length) {
        return actSum;
      }
      if (!this.checkedActs.length) {
        return paySum;
      }
      return Math.min(actSum, paySum);
    },
    idCellStyles() {
      return ACTS_STATUSES_MAP;
    },
  },
  watch: {
    items(val) {
      const sums = {
        actsCommon: this.getSum('sum_act'),
        payCommon: this.getSum('sum_pay'),
      };
      this.$store.commit('contractsData/SET_ACTS_SUMMARY', sums);
    },
  },
  created() {
    this.setDefaultFilter();
  },
  mounted() {
    const sums = {
      actsCommon: this.getSum('sum_act'),
      payCommon: this.getSum('sum_pay'),
    };
    this.$store.commit('contractsData/SET_ACTS_SUMMARY', sums);
    this.$refs.resizer.addEventListener('mousedown', this.startResize);
    if (this.checkedActs.length) {
      this.scrollToActive(this.checkedActs[0]);
    }
  },
  methods: {
    startDrag(evt) {
      evt.dataTransfer.setData('itemID', evt.target.dataset.id);
    },
    onDragOver(evt, id) {
      this.dragoverId = id;
    },
    async onDrop(evt, id) {
      const dropId = +evt.dataTransfer.getData('itemID');
      if (dropId === id) {
        return;
      }
      this.mergeParams = {
        id_act_victim: dropId,
        id_act: id,
      };
      this.showMergeConfirm = true;
    },
    async mergeActs() {
      const merge = await this.$store.dispatch('contractsData/mergeActs', this.mergeParams);
      this.mergeParams = null;
      if (merge) {
        this.refreshData();
      }
    },
    expand(id) {
      if (this.expanded === id) {
        this.expanded = null;
        return;
      }
      this.expanded = id;
    },
    reset() {
      this.filterByAct = [];
      this.showUnbindingButton = false;
    },
    onSelect(index) {
      this.selectedFilter = this.paydFilter[index];
    },
    setDefaultFilter() {
      this.selectedFilter = this.paydFilter.at(-1);
    },
    onSumActSelect(act) {
      this.selectedAct = act;
      if (!act.list_pay) {
        return;
      }
      try {
        const listPay = JSON.parse(act.list_pay);
        this.filterByAct = listPay.map((item) => item.id_pay);
      } catch (error) {
        console.log(error);
      }
    },
    async highlightAct(params) {
      const bindedAct = this.items.find((item) => item.id === params.id);
      if (bindedAct) {
        this.showUnbindingButton = true;
        this.checkedActs = [bindedAct.id];
        this.scrollToActive(bindedAct.id);
        this.idActPay = params.idActPay;
      }
    },
    setAct(id) {
      if (this.checkedActs.length) {
        this.checkedActs = [id];
        this.showBindingButton = true;
      } else if (!this.checkedActs.length && !this.checkedPayments.length) {
        this.showBindingButton = false;
      }
    },
    switchButton(val) {
      this.showBindingButton = val;
    },
    async loadPatients() {
      const params = {
        includeServices: false,
        actId: this.activeActId,
      };
      this.emitter.emit('globalLoading', true);
      const patients = await this.$store.dispatch('employeesData/getLastPatients', params);
      this.emitter.emit('globalLoading', false);
      if (patients) {
        const actData = {
          val: true,
          actId: this.activeActId,
          sum: this.getActSum(),
        };
        this.$store.commit('employeesData/SET_ACT_TO_LOADED', actData);
        this.$store.commit('employeesData/IS_PATIENTS_LOADED', false);
        this.$router.push({ name: 'Structure', params: { acts: true } });
      }
    },
    openActDialog(id) {
      this.isActDialog = true;
      this.activeActId = id;
    },
    getActSum() {
      const act = this.$store.state.contractsData.acts.find((item) => item.id === this.activeActId);
      return act?.sum_act;
    },
    async bindPayment(sum = null) {
      if (!this.checkedActs.length || !this.checkedPayments.length) {
        return;
      }
      const params = {
        id_act: this.checkedActs[0],
        id_pay: this.checkedPayments[0],
        summ_pay: sum || this.calculatedSum,
      };
      const result = await this.$store.dispatch('contractsData/bindPayment', params);
      if (result) {
        switch (result.err) {
          case 0:
            this.emitter.emit('message', result.err_text);
            this.refreshData();
            break;
          case 1:
            this.emitter.emit('message', result.err_text);
            break;
          default:
            break;
        }
      }
    },
    async unbindPayment() {
      this.emitter.emit('globalLoading', true);
      const result = await this.$store.dispatch('contractsData/unbindPayment', this.idActPay);
      if (result) {
        // this.checkedActs = [];
        this.$emit('refresh');
        await this.$store.dispatch('contractsData/getPaymentList');
      }
      this.emitter.emit('globalLoading', false);
    },
    async refreshData() {
      this.emitter.emit('globalLoading', true);
      this.$emit('refresh');
      await this.$store.dispatch('contractsData/getPaymentList');
      this.emitter.emit('globalLoading', false);
    },
    async approveAct(id) {
      this.isLoading = true;
      const approve = await this.$store.dispatch('contractsData/approveAct', id);
      if (approve) {
        this.$emit('refresh');
      }
      this.isLoading = false;
    },
    async cancelAct(id) {
      this.isLoading = true;
      const cancel = await this.$store.dispatch('contractsData/cancelAct', id);
      if (cancel) {
        this.$emit('refresh');
      }
      this.isLoading = false;
    },
    async createAct() {
      this.isLoading = true;
      const result = await this.$store.dispatch('contractsData/createAct');
      this.isLoading = false;
      this.showActCreateConfirm = false;
      if (result.err !== 0) {
        this.emitter.emit('message', result.err_text);
      } else {
        this.$emit('refresh');
      }
    },
  },
};
</script>

<style lang="scss">
.row_clickable{
  cursor: pointer;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
.act__expanded-table td {
  text-align: right;
  padding: 0 10px;
}
.dragover {
  background-color: rgba(green, 0.1);
  color: green;
  font-weight: bold;
}
</style>
