<template>
  <div
  class="table__container patients-table"
  ref="container"
  :style="height"
  @dragover.prevent
  @dragenter.prevent
  @dragend="clearHighLight"
  @drop.capture="onDrop($event)">
        <div class="table__header">
        <h2 class="table__title">
          {{title}}
          <span class='table-summary' v-if='items.length'>
            | Записей: {{items.length}} |
          </span>
          <mcw-switch
            v-model="myRecords"
            :label="myRecords ? 'Все записи' : 'Мои записи'"
            class="table__checkbox-wrapper" />
        </h2>
          <mcw-textfield
            v-model.trim="searchString"
            placeholder="Поиск (ФИО)"
            class="table__filter"
            dense
            outline/>
          <mcw-button class="table__export-btn" @click="exportToExcel" v-if='items.length'>
            <mcw-material-icon
              class="icon_base"
              icon="download">
            </mcw-material-icon>
            Экпорт в excel
          </mcw-button>
        <mcw-icon-button
          class="table__close-btn"
          v-if="items.length"
          @click="closeTable">
          <mcw-material-icon icon="close" />
        </mcw-icon-button>
      </div>
  <div
    class="table"
    >
    <mdc-data-table>
      <table class="mdc-data-table__table">
        <thead>
          <tr class="mdc-data-table__header-row">
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--checkbox"
              role="columnheader"
              scope="col">
              <mcw-checkbox
                v-model="select"
                @change="selectAll('id_people')"
                ref="rowCheckbox"
                class="mdc-data-table__header-row-checkbox">
              </mcw-checkbox>
            </th>
            <th class="mdc-data-table__header-cell">
              <span>Визиты</span>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell with-wrap">
              <span>Создать контакт</span>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell with-wrap">
              <span>Добавить пациента</span>
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('fio')">
              {{transcriptColumnTitle('fio')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell">
              {{transcriptColumnTitle('snils')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('sex')">
              {{transcriptColumnTitle('sex')}}
            </th>
            <th
              class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort mdc-data-table__header-cell--numeric"
              @click="sortTable('datebirth', 'date')">
              {{transcriptColumnTitle('datebirth')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('post')">
              {{transcriptColumnTitle('post')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell--with-sort" @click="sortTable('type_enrol')">
              Тип
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell" @click="sortTable('tab_number')">
              {{transcriptColumnTitle('tab_number')}}
            </th>
            <th class="mdc-data-table__header-cell mdc-data-table__header-cell" @click="sortTable('departament')">
              {{transcriptColumnTitle('departament')}}
            </th>
          </tr>
        </thead>
        <tbody
          class="mdc-data-table__content">
          <Spinner v-show="isLoading"/>
          <template
            v-for="item in visibleItems"
            :key="item.id_tree_people"
            draggable="true"
            @dragstart="startDrag($event, item)">
          <tr class="mdc-data-table__row row_draggable"
            v-show="!filteredData.length || filteredData.includes(item)">
            <td class="mdc-data-table__cell mdc-data-table__cell--checkbox">
              <mdc-checkbox
                class="mdc-data-table__row-checkbox"
                :value="item.id_people"
                ref="rowCheckbox"
                v-model="checkedItems">
              </mdc-checkbox>
            </td>
            <td class="table__cell">
              <mcw-button
                :disabled="!item.amount_vizits && type === 'patients'"
                class="all-patient-table-btn visits"
                @click.stop="showPatientInfo(item)">
                <mcw-material-icon icon="info" />
                <span class="contacts-qty" v-if='item.amount_vizits'>
                  {{item.amount_vizits}}
                </span>
                </mcw-button>
            </td>
            <td class="table__cell">
              <mcw-icon-button
                @click="addNewContact(item)">
                <mcw-material-icon icon="person_add_alt" />
              </mcw-icon-button>
            </td>
            <td class="table__cell">
              <mcw-icon-button
                @click='openNewPatientModal(item)'>
                <mcw-material-icon icon="assignment" />
              </mcw-icon-button>
            </td>
            <td class="table__cell">{{item.fio}}</td>
            <td class="table__cell">{{item.snils}}</td>
            <td class="table__cell">{{item.sex}}</td>
            <td class="table__cell">{{item.datebirth}}</td>
            <td class="table__cell">{{item.post}}</td>
            <td class="table__cell">{{item.type_enrol}}</td>
            <td class="table__cell">
              <EditableCell
                :item="item"
                editableField="tab_number"
                @saveEditableCell="saveTabNumber"/>
            </td>
            <td class="table__cell">{{item.departament}}</td>
          </tr>
          <tr>
            <td colspan="12">
              <PatientDataTable
                v-if="Object.keys(currentPatient).length
                  && currentPatientInfo.length
                  && (currentPatient.id_tree_people === item.id_tree_people)"
                :patient="currentPatient"
                :items="currentPatientInfo" />
            </td>
          </tr>
          </template>
        </tbody>
      </table>
    </mdc-data-table>
    <mcw-dialog v-model="isNewPatientModalOpen">
      <div class="add-new-patient" v-if="isNewPatientModalOpen">
        <mcw-dialog-title>Добавить нового пациента</mcw-dialog-title>
        <mcw-dialog-content>
            <div class="form-group">
              <mcw-select
                class="select_dense"
                v-model="selectedMOType"
                outlined
                label="Тип МО">
              <mcw-list-item
                v-for="type of MOTypes"
                :key="type.id_reference"
                :data-value="type.id_reference"
                >{{ type.name_reference }}</mcw-list-item>
              </mcw-select>
              <AvailableContracts @selectContract="setContract"/>
            </div>
        </mcw-dialog-content>
        <mcw-dialog-footer>
          <mcw-dialog-button
            @click="addPatient"
            :disabled="canISave"
            >Добавить</mcw-dialog-button>
          <mcw-dialog-button isDefault action="dismiss">отмена</mcw-dialog-button>
        </mcw-dialog-footer>
      </div>
    </mcw-dialog>
    <mdc-dialog
      v-model="isDialogOpen"
      escape-key-action="close"
      scrim-click-action="close"
      aria-labelledby="my-basic-title"
      aria-describedby="my-basic-content"
      :auto-stack-buttons="true">
      <component
        :is="componentName"
        v-bind="{
          title: dialogTitle,
          division: divisionId,
          position: positionId,
          patients: checkedItems,
          moveId: moveId,
          tableType: type,
        }" @finishEditing="finishEditing">
      </component>
    </mdc-dialog>
  </div>
      <transition name="fade">
       <div
        class="table__actions"
        v-show="checkedItems.length" v-if="type === 'patients'">
      <mdc-button
        @click="openRemoveDialog"
        :disabled="!checkedItems.length">
        Удалить
      </mdc-button>
      <mdc-menu-anchor class="myAnchor">
        <mdc-button
          @click="isMenuOpen = true"
          :disabled="!checkedItems.length">
          Перенести на другую должность
        </mdc-button>
        <mdc-menu v-model="isMenuOpen">
          <h4 class="table__dropdown-header">Выберите новую должность</h4>
          <li role="separator" class="mdc-list-divider"></li>
          <mdc-list-item
            v-for="position in positions"
            :key="position.id"
            @click="selectMoveType(position.id)">
            {{ position.text }}
          </mdc-list-item>
        </mdc-menu>
      </mdc-menu-anchor>
    </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CommonTable from './CommonTable.vue';
import PatientDataTable from '../PatientDataTable.vue';
import AvailableContracts from '../AvailableContracts.vue';

export default {
  name: 'PatientsTable',
  extends: CommonTable,
  props: {
    items: Array,
    tableHeight: Number,
    divisionId: Number,
    positionId: Number,
  },
  components: {
    PatientDataTable,
    AvailableContracts,
  },
  data() {
    return {
      title: 'Штатная расстановка',
      type: 'patients',
      isMenuOpen: false,
      moveId: null,
      isNewPatientModalOpen: false,
      selectedMOType: null,
      selectedEmployee: null,
    };
  },
  created() {
    this.emitter.on('attachPatient', (data) => {
      if (this.type === data.patient.table) {
        if (!this.checkedItems.includes(data.patient.item.id_tree_people)) {
          this.checkedItems.push(data.patient.item.id_tree_people);
        }
        this.selectMoveType(data.node);
      }
    });
  },
  computed: {
    position() {
      return this.$store.getters['treeData/getItemById'](this.positionId).text;
    },
    positions() {
      return this.$store.getters['treeData/getPositions'];
    },
    _currentContract: {
      get() { return this.currentContract; },
      set(value) { this.SET_CURRENT_CONTRACT(value); },
    },
    canISave() {
      if (this._currentContract && this.selectedMOType) return false;
      return true;
    },
    ...mapState({
      currentContract: (state) => state.contractsData.currentContract,
      contracts: (state) => state.contractsData.contracts,
      MOTypes: (state) => state.treeData.MOTypes,
    }),
  },
  methods: {
    setContract(contract) {
      this._currentContract = contract.id_contract;
    },
    closeTable() {
      this.$store.commit(`employeesData/RESET_${this.type.toUpperCase()}_TABLE`);
      if (this.currentContract) this.$store.dispatch('employeesData/getLastPatients', { includeServices: false });
    },
    selectMoveType(id) {
      this.moveId = id;
      this.dialogTitle = 'Выберите действие с ';
      this.componentName = 'AttachPatient';
      this.isDialogOpen = true;
    },
    openRemoveDialog() {
      this.dialogTitle = 'Вы действительно хотите убрать из должности';
      this.isDialogOpen = true;
      this.componentName = 'RemovePatient';
    },
    openEditDialog() {
      this.dialogTitle = 'Изменение пациента';
      this.isDialogOpen = true;
      this.componentName = 'EditPatient';
    },
    openNewPatientModal(item) {
      this.selectedEmployee = item;
      this.isNewPatientModalOpen = true;
    },
    addPatient() {
      const params = {
        id_tree_people: this.selectedEmployee.id_tree_people,
        id_contract: this._currentContract,
        type_mo: this.selectedMOType,
        id_people: this.selectedEmployee.id_people,
        create_patient: '1',
      };
      this.$store.dispatch('treeData/addPatient', params);
      this.isNewPatientModalOpen = false;
      this.selectedMOType = null;
      this.selectedEmployee = null;
    },
    ...mapMutations('contractsData', ['SET_CURRENT_CONTRACT']),
  },
};
</script>

<style>
.with-wrap {
  width: 100px;
  white-space: break-spaces !important;
}
.contacts-qty {
  color: #fff;
}
.disabled-item {
  pointer-events: none;
  opacity: 0.5;
}
.patients-table{
  grid-template-rows: auto 1fr;
}
.patients-table .mdc-dialog__content{
  overflow-y: unset!important
}
</style>
