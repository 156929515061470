import Api from '../helpers/Api';
import { modules } from '../constants';
import { setInitialDatesRange } from '../helpers/setInitialDatesRange';

export const employeesData = {
  namespaced: true,
  state: {
    employees: [],
    patients: [],
    docList: [],
    questioned: [],
    typeLoad: 0,
    allPatients: [],
    highLightTable: false,
    isPatientsLoaded: false,
    actLoaded: null,
    patientsDatesRange: setInitialDatesRange(),
    countries: [],
    missions: [],
    tableType: null,
    processing: false,
    foreignTypes: [],
    typesForGenerate: [],
    showOnePatientResults: false,
  },
  mutations: {
    SET_DATES_RANGE(state, range) {
      state.patientsDatesRange = range;
    },
    PROCESSING(state, payload) {
      state.processing = payload;
    },
    SET_ACT_TO_LOADED(state, data) {
      state.actLoaded = data;
    },
    SET_TABLE_TYPE(state, payload) {
      state.tableType = payload;
    },
    SET_TYPE_LOAD(state, type) {
      state.typeLoad = type;
    },
    RESET_EMPLOYEES_TABLE(state) {
      state.employees = [];
    },
    RESET_PATIENTS_TABLE(state) {
      state.patients = [];
    },
    RESET_QUESTIONED_TABLE(state) {
      state.questioned = [];
    },
    ADD_PATIENT(state, data) {
      state.patients.push({
        fio: data.fio,
        id: state.patients.length + 2,
        id_tree_people: data.positionId,
      });
    },
    CHANGE_POSITION(state, data) {
      data.patients.forEach((item) => {
        const patient = state.patients.find((el) => el.id_people === item);
        patient.id_tree_people = data.newPosition;
      });
    },
    REMOVE_PATIENT(state, id) {
      const index = state.patients.findIndex((el) => el.id === id);
      state.patients.splice(index, 1);
    },
    SET_EMPLOYEES(state, staffList) {
      state.employees = staffList;
    },
    SET_PATIENTS(state, staffList) {
      state.patients = staffList;
    },
    SORT_TABLE(state, arr) {
      state.patients = arr;
    },
    FOREIGN_DOC_TYPES(state, payload) {
      const types = JSON.parse(payload[0].doc_types);
      state.foreignTypes = types;
    },
    SAVE_PATIENTS(state, data) {
      let existedPatient = state.patients.find((item) => item.id_tree_people === data.id_tree_people);
      if (existedPatient) {
        existedPatient = data;
      } else {
        state.patients.push(data);
      }
    },
    SET_DOC_LIST(state, data) {
      state.docList = data;
    },
    REMOVE_CURRENT_PATIENTS(state, data) {
      state.patients.forEach((patient, index) => {
        if (data.includes(patient.id_tree_people)) {
          state.patients.splice(index, 1);
        }
      });
    },
    REMOVE_PATIENTS(state, data) {
      data.forEach((patient) => {
        const index = state.patients.findIndex((item) => item.id_people === patient.id_people);
        state.patients.splice(index, 1);
      });
    },
    MOVE_PATIENTS(state, data) {
      if (data.type === 0) {
        data.patients.forEach((item) => {
          const index = state.patients.findIndex((patient) => patient.id_people === item);
          if (index !== -1) {
            state.patients.splice(index, 1);
          }
        });
      }
    },
    SET_TAB_NUMBER(state, data) {
      const patient = state.patients.find((item) => item.id_tree_people === data.id_tree_people);
      if (patient) {
        patient.tab_number = data.tabNumber;
      }
    },
    SET_ALL_PATIENTS(state, data) {
      if (data) {
        state.allPatients = data;
      }
    },
    HIGHLIGHT_TABLE(state, value) {
      state.highLightTable = value;
    },
    IS_PATIENTS_LOADED(state, val) {
      state.isPatientsLoaded = val;
    },
    SET_QUESTIONED(state, data) {
      state.questioned = data;
    },
    SET_STAFF_STATUS(state, params) {
      const staff = state.allPatients.find((item) => item.id_people === params.id);
      if (staff) {
        staff.id_tree_people = params.newId;
      }
    },
    MISSIONS(state, payload) {
      state.missions = payload;
    },
    COUNTRIES(state, payload) {
      state.countries = payload;
    },
    DOC_TYPES_FOR_GENERATE(state, types) {
      state.typesForGenerate = types;
    },
    SET_SHOW_ONE_PATIENT_RESULT(state, val) {
      state.showOnePatientResults = val;
    },
  },
  actions: {
    async savePatient({ commit, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.text = params.tab_number;
      data.id_tree_people = params.id_tree_people;
      data.Name_event = 'POST:tab_number';
      const changeTabNumber = await Api.post(data, true);
      if (changeTabNumber) {
        commit('SAVE_PATIENT', params);
      }
    },
    async setTabNumber({ commit, rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.text = params.tabNumber;
      data.id_tree_people = params.id_tree_people;
      data.Name_event = 'POST:tab_number';
      const changeTabNumber = await Api.post(data, true);
      if (changeTabNumber.errorType === 0) {
        commit('SET_TAB_NUMBER', params);
        return true;
      }
      return false;
    },
    changePosition({ commit }, data) {
      commit('CHANGE_POSITION', data);
    },
    async removeSinglePatient({ rootState }, patient) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:staff_del';
      data.id_tree_people = patient.id_tree_people;
      await Api.post(data, true);
      return patient;
    },
    async removePatients({ dispatch, commit }, patients) {
      const remove = Promise.allSettled(
        patients.map((item) => dispatch('removeSinglePatient', item)),
      );
      remove.then((results) => {
        const removedPatients = [];
        results.forEach((result) => {
          removedPatients.push(result.value);
        });
        commit('REMOVE_PATIENTS', removedPatients);
      });
      // dispatch('getLastPatients', { includeServices: false });
      return remove;
    },
    async loadPatients({ commit, rootState, dispatch }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:tree_staff_list';
      data.id_tree = params.id;
      data.type_drop = params.type;
      const staffList = await Api.get(data);
      if (staffList.body && !params.commit) {
        commit('SET_PATIENTS', staffList.body);
        commit('SET_TYPE_LOAD', params.type);
      }
      if (!staffList.body.length && rootState.contractsData.currentContract) {
        dispatch('getLastPatients', { includeServices: false });
      }
      return staffList;
    },
    async getDocList({ commit, rootState }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:employee_list';
      data.id_org = rootState.user.selectedCompany.id_org;
      if (rootState.contractsData.currentContract) {
        data.id_contract = rootState.contractsData.currentContract;
      }
      const docList = await Api.get(data);
      if (docList && docList.body.length) {
        commit('SET_DOC_LIST', docList.body);
      }
      return docList.body;
    },
    async getUploads({ state, commit, dispatch }, type) {
      const list = state.docList;
      if (list) {
        Promise.allSettled(list.map((item) => dispatch('getStaffList', item)))
          .then((results) => {
            const staffList = [];
            results.forEach((result) => {
              if (result.value.body.length < 100) {
                staffList.push(...result.value.body);
              }
            });
            commit('SET_EMPLOYEES', staffList);
          })
          .catch((error) => console.log(error));
      }
    },
    async getStaffList({ rootState }, list) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:employee';
      data.id_org = rootState.user.selectedCompany.id_org;
      data.date_upload = list.date_upload;
      data.id_list = list.id_list;
      const staff = await Api.get(data);
      return staff;
    },
    // eslint-disable-next-line object-curly-newline
    async attachEmployee({ state, commit, dispatch, rootState }, params) {
      if (state.processing) return;
      commit('PROCESSING', true);
      const data = { ...rootState.user.constantParams };
      data.id_tree = params.id_tree;
      data.id_employee = params.id_employee;
      data.id_patient = params.id_patient;
      data.Name_event = 'POST:staff_from_employee';
      const attach = await Api.post(data, true);
      if (attach && attach.errorType === 0 && !attach.body) {
        dispatch('loadPatients', { id: params.id_tree, type_drop: state.typeLoad });
        commit('PROCESSING', false);
        return true;
      }
      commit('PROCESSING', false);
      return attach;
    },
    async movePatients({ commit, dispatch }, params) {
      const move = Promise.allSettled(
        params.patients.map((item) => dispatch('moveSinglePatient', {
          patient: item,
          type: params.type,
          newNode: params.newNode,
        })),
      );
      move.then((results) => {
        const movedPatients = [];
        results.forEach((result) => {
          movedPatients.push(result.value);
        });
        commit('MOVE_PATIENTS', params);
      });
      return move;
    },
    async moveSinglePatient({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:staff_move';
      data.id_tree = params.newNode;
      data.id_tree_people = params.patient;
      data.type = params.type;
      const result = await Api.post(data, true);
      return result;
    },
    async getPatientInfo({ rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:patient_list';
      data.id_people = id;
      data.text = 'tst';
      const result = await Api.get(data);
      if (result.body) {
        return JSON.parse(result.body[0].patient_list);
      }
      return false;
    },
    async getLastPatients(
      { commit, state, rootState },
      {
        includeServices, actId, doer, returnOnly = null,
      },
    ) {
      if (!rootState.user.selectedCompany) return;
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:patient_org_list';
      data.id_org = rootState.user.selectedCompany.id_org;
      data.need_services = Number(includeServices);
      if (actId) {
        data.id_act = actId;
      }
      if (doer) {
        data.id_subdoer = doer;
      }
      if (rootState.contractsData.currentContract) {
        data.id_contract = rootState.contractsData.currentContract;
      } else {
        data.date_from = state.patientsDatesRange.start;
        data.date_till = state.patientsDatesRange.end;
      }
      commit('IS_PATIENTS_LOADED', true);
      const result = await Api.get(data);
      if (result.errorType === 0 && result.body) {
        if (returnOnly) {
          commit('IS_PATIENTS_LOADED', false);
          return result.body;
        }
        commit('SET_ALL_PATIENTS', result.body);
        commit('SET_SHOW_ONE_PATIENT_RESULT', false);
        commit('IS_PATIENTS_LOADED', false);
        return true;
      }
      return false;
    },
    async getOnePatient({ commit, rootState }, param) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:patient_org_list_one';
      data.id_org = rootState.user.selectedCompany.id_org;
      data.str_param = param;
      const result = await Api.get(data);
      if (result.errorType === 0) {
        commit('SET_ALL_PATIENTS', result.body);
        commit('SET_SHOW_ONE_PATIENT_RESULT', true);
      }
      return false;
    },
    async getDocsList({ rootState }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:doc_patient_list';
      data.id_patient = id;
      const result = await Api.get(data);
      if (result.errorType === 0) {
        return result.body;
      }
      return false;
    },
    async getQuestioned({ rootState, commit }, period) {
      const data = { ...rootState.user.constantParams };
      data.id_org = rootState.user.selectedCompany.id_org;
      data.Name_event = 'GET:employee_poll_sheets';
      data.date_from = period ? period.date_from : '';
      data.date_till = period ? period.date_till : '';
      const result = await Api.get(data);
      if (result && result.body.length > 0) {
        commit('SET_QUESTIONED', result.body);
        return true;
      }
      commit('SET_QUESTIONED', result.body);
      return false;
    },
    // eslint-disable-next-line object-curly-newline
    async approveSelfRecord({ state, commit, rootState, dispatch }, { params, source }) {
      if (state.processing) return;
      commit('PROCESSING', true);
      let data = { ...rootState.user.constantParams };
      data.id_org = rootState.user.selectedCompany.id_org;
      data = Object.assign(data, params);
      data.Name_event = 'POST:approve_self_record';
      // data.id_tree = rootState.treeData.tree.id;
      const result = await Api.post(data, true);
      if (result.body && !result.body[0].err) {
        switch (source) {
          case 0:
            dispatch('getLastPatients', { includeServices: false });
            break;
          // case 1: dispatch('getQuestioned', null); break;
          default:
            break;
        }
        commit('PROCESSING', false);
        return result;
      }
      commit('PROCESSING', false);
      return false;
    },
    async getNapravl({ rootState, commit }, id) {
      const data = { ...rootState.user.constantParams };
      data.id_patient = id;
      data.Name_event = 'GET:gen_napravl';
      data.Name_module = 'module_patients';
      const result = await Api.get(data);
      if (result && result.body.length > 0) {
        return result.body;
      }
    },
    async getForeingData({ rootState, commit }, id) {
      const data = { ...rootState.user.constantParams };
      data.id_patient = id;
      data.Name_event = 'GET:foreign_data';
      data.Name_module = 'module_patients';
      const result = await Api.get(data);
      if (result && result.body.length) {
        return result.body;
      }
    },
    async postForeingData({ rootState, dispatch }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:foreign_data';
      data.Name_module = 'module_patients';
      data.str_params = JSON.stringify(params);
      const result = await Api.post(data, true);
      if (result) {
        await dispatch('getForeingData', params.id_patient);
      }
    },
    async getMissionList({ rootState, commit }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:purpose_list';
      data.Name_module = 'module_patients';
      const result = await Api.get(data);
      if (result && result.body.length) {
        commit('MISSIONS', result.body);
      }
    },
    async getCountriesList({ rootState, commit }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:countries_list';
      data.Name_module = 'module_patients';
      const result = await Api.get(data);
      if (result && result.body.length) {
        commit('COUNTRIES', result.body);
      }
    },
    async getForeignPatientDocs({ rootState, commit }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:patients_docs';
      data.Name_module = 'module_patients';
      data.id_patient = id;
      const result = await Api.get(data);
      if (result && result.body.length) {
        return result.body[0];
      }
      return null;
    },
    async getForeignDocsTypes({ rootState, commit }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:doc_form_types';
      data.Name_module = 'module_patients';
      const result = await Api.get(data);
      if (result && result.body.length) {
        commit('FOREIGN_DOC_TYPES', result.body);
      }
    },
    async getTypesForGenerate({ rootState, commit }) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:doc_gen_types';
      data.Name_module = 'module_patients';
      const result = await Api.get(data);
      if (result && result.body.length) {
        commit('DOC_TYPES_FOR_GENERATE', result.body);
      }
    },
    async orderGeneration({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_event = 'POST:order_document';
      data.Name_module = 'module_patients';
      const result = await Api.post(data, true);
      if (result) {
        return true;
      }
      return false;
    },
    async postForeingDocData({ rootState, dispatch }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:doc_save';
      data.Name_module = 'module_patients';
      data.str_params = params;
      const result = await Api.post(data, true);
      if (result) {
        return true;
      }
      return false;
    },
    async getPatientAddress({ rootState, commit }, id) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:patient_address';
      data.Name_module = 'module_patients';
      data.id_patient = id;
      const result = await Api.get(data);
      if (result) {
        return result.body[0];
      }
      return null;
    },
    async postPatientAddress({ rootState, dispatch }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:patient_address';
      data.Name_module = 'module_patients';
      data.str_params = JSON.stringify({
        id_patient: params.id_patient,
        text_address: params.text_address,
        dadata: params.dadata,
        id_address: params.id_address,
      });
      const result = await Api.post(data, true);
      if (result) {
        return true;
      }
      return false;
    },
    async postEmployeeJsonFromXlsx({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_event = 'POST:employee';
      data.Name_module = 'module_calculation';
      const result = await Api.sendLargeJson(data);
      if (!result.errorType) {
        return true;
      }
      return false;
    },

    async editPatient({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:patient_edit';
      data.Name_module = 'module_patients';
      data.id_patient = params.id_patient;
      data.confirm_par = 1;
      data.str_params = JSON.stringify({
        lastname: params.lastname,
        firstname: params.firstname,
        middlename: params.middlename,
        sex: params.sex === 'м' ? 1 : 2,
        birthdate: params.bd,
      });
      const result = await Api.post(data, true);
      if (result) {
        return true;
      }
      return false;
    },
    async priceCalculation({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'POST:price_append_calculation';
      data.Name_module = 'module_calculation';
      data.id_employee_list = params.idList;
      data.id_contract = params.idContract;
      const result = await Api.post(data, true);
      if (result.body[0].err === 0) {
        return true;
      }
      return false;
    },
    async loadListAttended({ rootState }, params) {
      const data = { ...rootState.user.constantParams };
      data.Name_event = 'GET:patient_list_attended';
      data.json_str = JSON.stringify(params);
      const result = await Api.get(data);
      if (result && result.body) {
        return result.body;
      }
      return false;
    },
    async regenDoc({ rootState }, params) {
      const data = { ...rootState.user.constantParams, ...params };
      data.Name_module = modules.patients;
      data.Name_event = 'POST:regen_document';
      const result = await Api.post(data, true);
      if (result) {
        return true;
      }
      return false;
    },
    async getESigns({ rootState }, document) {
      const data = {
        ...rootState.user.constantParams,
        Name_module: modules.medAdmin,
        Name_event: 'GET:e_signs_cat',
        ...document,
      };
      const result = await Api.get(data);
      return result.body;
    },
    async signDocument({ rootState }, params) {
      const data = {
        ...rootState.user.constantParams,
        Name_module: modules.medAdmin,
        Name_event: 'POST:regen_doc_w_esign',
        ...params,
      };
      const result = await Api.post(data, true);
      if (result && result.body) {
        return result.body[0];
      }
      return false;
    },
    async loadPaymentTypes({ rootState }) {
      const data = {
        ...rootState.user.constantParams,
        Name_module: modules.patients,
        Name_event: 'GET:spr_payment_type',
      };
      const result = await Api.get(data);
      if (result && result.body) {
        return result.body;
      }
      return false;
    },
    async employeeToTree({ rootState }, params) {
      const data = {
        ...rootState.user.constantParams,
        Name_module: modules.calculation,
        Name_event: 'POST:employee_to_tree',
        ...params,
      };
      const result = await Api.post(data, true);
      if (result && result.body) {
        return result.body[0];
      }
      return false;
    },
    async checkEmployeeToPatient({ rootState, dispatch }, params) {
      const data = {
        ...rootState.user.constantParams,
        Name_module: modules.calculation,
        Name_event: 'POST:employee_to_patient_pt1',
        ...params,
      };
      const result = await Api.post(data, true);
      if (result && !result.errorType && result.body) {
        await dispatch('employeeToPatient', params);
        await dispatch('getDocList');
        return true;
      }
      return false;
    },
    async employeeToPatient({ rootState }, params) {
      const data = {
        ...rootState.user.constantParams,
        Name_module: modules.calculation,
        Name_event: 'POST:employee_to_patient_pt2_delayed ',
        ...params,
      };
      await Api.post(data, true);
    },
  },
  getters: {
    getPatientsByDivision: (state) => (id) => state.patients.filter((item) => item.id_tree_people === id),
    getPatients(state) {
      return state.patients;
    },
    getEmployees(state) {
      return state.employees;
    },
    getPatientById: (state) => (id) => state.patients.find((item) => item.id_people === id),
    getAllPatients(state) {
      return state.allPatients;
    },
    getQuestioned(state) {
      return state.questioned;
    },
    getPatientFromAll: (state) => (id) => state.allPatients.find((item) => item.id_people === id),
    showOnePatientResults(state) {
      return state.showOnePatientResults;
    },
  },
};
